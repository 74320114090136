import React, { useState } from 'react';
import styled from 'styled-components';
import AnchorLink from './AnchorLink';
import LangSwapLink from './LangSwapLink';
import { Link } from 'gatsby';
import Burger from '../Burger/Burger';
import { LOCALES } from 'intl/locales';

const language = LOCALES.POLISH

const Header = styled.header`
  box-shadow: 0 2px 4px hsl(0 0% 30% / 30%);
  width: 100%;
  left: 0;
  top: 0;
  z-index: 11;
  /* position: ${({ isOpen }) => (isOpen ? 'fixed' : 'static')}; */
  position: sticky;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(250,250,250,.8);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    z-index: -1;
  }

  .logo {
    position: relative;
    z-index: 4;

    ${({ theme }) => theme.mqMobile} {
      img {
        height: auto;
        max-width: 80px;
      }
    }
  }

  img {
    margin-top: 8px;
    max-width: none;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 70px;
    padding: 5px 15px;
  }

  ${({ theme }) => theme.mqTablet} {
    height: auto;

    .row {
      align-items: center;
      padding: 0 15px;
    }
  }
`;

const NavWrap = styled.div`
  align-items: center;
  background: white;
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  left: 0;
  padding: 80px 0 0;
  position: ${({ isOpen }) => (isOpen ? 'fixed' : 'static')};;
  top: 0;
  width: 100%;
  z-index: 3;

  ${({ theme }) => theme.mqTablet} {
    background: none;
    display: flex;
    justify-content: end;
    height: auto;
    position: relative;
    padding: 0;
    flex-direction: row;
  }
`;

const Nav = styled.nav`
  a {
    color: #323232;
    display: block;
    font-size: 2.4rem;
    font-weight: 600;
    height: auto;
    line-height: normal;
    padding: 3px 0;

    &:hover,
    &:focus {
      color: #78569D;
      text-decoration: none;
    }
  }

  li {
    margin: 32px 0;

    &.disabled {
      display: none;
    }
  }

  ${({ theme }) => theme.mqMobile} {
    overflow: auto;
    padding: 0 0 40px;
    width: 100%;

    a {
      font-size: 3.6rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 12px 40px;
    }
  }

  ${({ theme }) => theme.mqTablet} {
    a {
      font-weight: 400;
      font-size: 1.6rem;
    }

    ul {
      list-style: none;
      margin: 0;

      > li {
        display: inline-block;
        margin: 0 20px;
        position: relative;
      }
    }
  }
`;

export const HeaderComponent = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeNavigation = () => {
    setIsOpen(false);
  };

  const toggleNavigation = () => {
    // e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <Header isOpen={isOpen}>
      <div className="row">
        <Link
          to="/"
          title="Fair Fur"
          className="logo"
          onClick={closeNavigation}
        >
          <img
            alt="Fair Fur"
            src="/logo.png"
            width="123"
            height="73"
          />
        </Link>

        <NavWrap isOpen={isOpen}>
          <Nav>
            <ul>
              <li>
                <AnchorLink language={language} to="https://femi-shop.com/kategoria-produktu/wszystko/pies-i-kot/" title="Gdzie kupić" onAnchorLinkClick={closeNavigation} id="menu_whereto"></AnchorLink>
              </li>
              <li>
                <AnchorLink language={language} to="/#misja" title="Misja" onAnchorLinkClick={closeNavigation} id="menu_mission"></AnchorLink>
              </li>
              <li>
                <AnchorLink language={language} to="/#produkty" title="Nasze produkty" onAnchorLinkClick={closeNavigation} id="menu_products"></AnchorLink>
              </li>
              <li>
                <AnchorLink language={language} to="/#o-nas" title="O nas" onAnchorLinkClick={closeNavigation} id="menu_about"></AnchorLink>
              </li>
              <li>
                <AnchorLink language={language} to="/#kontakt" title="Skontaktuj się z nami" onAnchorLinkClick={closeNavigation} id="menu_contact"></AnchorLink>
              </li>
              <li>
                <LangSwapLink language={language}></LangSwapLink>
              </li>
            </ul>
          </Nav>
        </NavWrap>

        <Burger isOpen={isOpen} setIsOpen={toggleNavigation} title="Menu" />
      </div>
    </Header>
  );
};

export default HeaderComponent;
